(function ()
{
    "use strict";


    /*****************************************************************************
     *
     * VARIABLE ELEMENT
     *
     ****************************************************************************/
    var html = document.querySelector('html');
    var timer =
    {
        'xloop': null,
        'scroll': null,
        'nav': null,
        'ripple': null,
        'textarea': null
    };

    var fn =
    {
        'isset': function (el)
        {
            return ((el !== null) && (el !== undefined));
        },

        'target': function (e)
        {
            e = (e || event);

            return (e.target || e.srcElement);
        },

        'evtListenerClick': function ()
        {
            return (fn.isTouch()) ? 'touchstart' : 'click';
        },

        'isTouch': function ()
        {
            return ('touchstart' in document.documentElement);
        },

        'isMobile': function ()
        {
            return (window.innerWidth < 1024);
        },

        'isDesktop': function ()
        {
            return (window.innerWidth >= 1024);
        },

        'coorClient': function (e)
        {
            e = (e || event);
            e = (fn.isTouch()) ? (e.touches[0] || e.changedTouches[0]) : e;

            return { x: e.clientX, y: e.clientY };
        },

        'style': function (el, pseudo)
        {
            return window.getComputedStyle(el, (fn.isset(pseudo) ? pseudo : null));
        },

        'prefix': function (pseudo)
        {
            var styles = window.getComputedStyle(document.documentElement, '');
            var pre = (Array.prototype.slice.call(styles).join('')
                .match(/-(moz|webkit|ms)-/) || (styles.OLink === '' && ['', 'o']))[1];

            return pre[0].toUpperCase() + pre.substr(1) + (pseudo.charAt(0).toUpperCase() + pseudo.slice(1));
        },

        'uiShortNumber': function (number, decimal)
        {
            const abbrev = ['', 'K', 'M', 'B', 'T', 'C', 'Q'];
            let short = 0;

            while (number >= 1000)
            {
                number /= 1000;

                if (short < (abbrev.length - 1)) short++;
            }

            number = (number % 1 === 0) ? number : number.toFixed((fn.isset(decimal) ? decimal : 1));

            return (number + abbrev[short]);
        },

        'uxTransitionDuration': function (el)
        {
            let obj = document.querySelector(el);

            return (fn.isset(obj)) ? parseInt(fn.style(obj).transitionDuration.replace(/[^0-9\.]/g, '') * 1000) : 0;
        },

        'uxAnimationDuration': function (el)
        {
            let obj = document.querySelector(el);

            return (fn.isset(obj)) ? parseInt(fn.style(obj).animationDuration.replace(/[^0-9\.]/g, '') * 1000) : 0;
        }
    };


    var ux =
    {
        'ripple':
        {
            'fastAnimations': 600,
            'simpleAnimations': 650,
            'mediumAnimations': 700,
            'complexAnimations': 750,
            'launchIn': 99,
            'launchOut': 0,
            'timming': 0.333333333,
            'animation': 0
        }
    };


    window.fn = fn;
    window.ux = ux;


    /*****************************************************************************
     *
     * EVENT LISTENER
     *
     ****************************************************************************/
    var optEventListener = false;

    try
    {
        const options =
        {
            get passive()
            {
                optEventListener = { passive: true, capture: true };
                return false;
            }
        };

        window.addEventListener("test", null, options);
        window.removeEventListener("test", null, options);

    } catch (err)
    {
        optEventListener = false;
    }


    setTimeout(() =>
    {
        window.addEventListener('resize', (e) =>
        {
            textAreaElements(0);
        }, false);
    });


    setTimeout(() =>
    {
        window.addEventListener('load', (e) =>
        {
            textAreaElements(0);
        }, false);
    });

    setTimeout(() =>
    {
        document.body.addEventListener(fn.evtListenerClick(), (e) =>
        {
            ripple(e);
        }, optEventListener);
    });


    setTimeout(() =>
    {
        document.body.addEventListener('touchmove', (e) =>
        {
            clearTimeoutNavigation();
        }, optEventListener);
    });


    setTimeout(() =>
    {
        document.body.addEventListener('keyup', (e) =>
        {
            clearTimeout(timer.textarea);
            textAreaAdjust(fn.target(e), 750);
        }, false);
    });


    /*****************************************************************************
     *
     * GROUP OF THE FUNCTIONS
     *
     ****************************************************************************/


    /**
     *
     */
    function clearTimeoutNavigation()
    {
        clearTimeout(timer.ripple);
        clearTimeout(timer.nav);
    }


    /**
     * DETECT IF THERE IS SCROLL
     *
     * @param {events} evt
     * @returns {undefined}
     */
    function isScrolled(evt)
    {
        clearTimeoutNavigation();
        clearTimeout(timer.scroll);

        timer.scroll = setTimeout(function ()
        {
            ((evt.target.scrollTop > 0) ? html.classList.add('ux-is-scrolled') : html.classList.remove('ux-is-scrolled'));
        }, 33);
    }


    /**
     * CREATE A RIPPLE EFFECT
     *
     * @param {events} evt
     * @returns {undefined}
     */
    function ripple(evt)
    {
        clearTimeoutNavigation();

        timer.ripple = setTimeout(function ()
        {
            var delay   = parseInt(window.ux.ripple.launchOut * window.ux.ripple.timming);
            /* var delay   = 100; */
            var target  = fn.target(evt);
            var el      = null;
            var isCheck = false;
            var zIndex  = 0;


            while (fn.isset(target) && (target.tagName !== 'HTML') && !fn.isset(el))
            {
                let zIndexTarget = fn.style(target).zIndex;

                zIndex = (zIndexTarget > zIndex) ? (parseInt(zIndexTarget) + 1) : zIndex;

                if (target.classList.contains('form-content'))
                {
                    let check = target.querySelector('.ux-ripple');

                    if (check)
                    {
                        isCheck = true;
                        el = check;
                        delay = 0;
                    }
                }

                if (target.classList.contains('ux-ripple') && (el === null)) el = target;

                target = target.parentNode;
            }


            clearTimeoutNavigation();
            timer.ripple = setTimeout(function ()
            {
                if (el)
                {
                    let ripple        = document.createElement('span');
                    let uxRipple      = document.createElement('span');
                    let elStyle       = fn.style(el);
                    let rect          = el.getBoundingClientRect();
                    let width         = el.offsetWidth;
                    let height        = el.offsetHeight;
                    let dx            = (isCheck) ? (width / 2) : (fn.coorClient(evt).x - rect.left);
                    let dy            = (isCheck) ? (height / 2) : (fn.coorClient(evt).y - rect.top);
                    let maxX          = Math.max(dx, (width - dx));
                    let maxY          = Math.max(dy, (height - dy));
                    let radius        = parseInt(Math.sqrt((maxX * maxX) + (maxY * maxY)));
                    let uxTimming     = (1 + window.ux.ripple.timming);
                    let uxTimmingFast = (1 + (window.ux.ripple.timming / 4));
                    let color         = el.dataset.uxRipple;
                    let colorCss      = [ '--ui-colors-md-500-grey' ];


                    window.ux.ripple.animation = parseInt(radius * uxTimming);

                    if (isCheck)
                    {
                        window.ux.ripple.animation = window.ux.ripple.complexAnimations;
                    }
                    else
                    {
                        window.ux.ripple.animation = (radius * uxTimming);

                        if (window.ux.ripple.animation < 512)
                        {
                            window.ux.ripple.animation = 512;
                        }
                        else
                        {
                            window.ux.ripple.animation = parseInt(window.ux.ripple.animation);
                        }
                    }

                    window.ux.ripple.launchOut = parseInt(window.ux.ripple.animation * window.ux.ripple.timming);


                    if (elStyle.position === 'static') el.style.position = 'relative';

                    ripple.classList.add('ux-ripple-wrapper');
                    uxRipple.classList.add('ux-ripple-effect');

                    ripple.appendChild(uxRipple);
                    el.appendChild(ripple);


                    ripple.style.zIndex        = zIndex;
                    ripple.style.pointerEvents = 'none';
                    ripple.style.borderRadius  = elStyle.borderRadius;

                    uxRipple.style.width  = (radius / 2) + 'px';
                    uxRipple.style.height = (radius / 2) + 'px';
                    uxRipple.style.top    = (dy - (radius / 4)) + 'px';
                    uxRipple.style.left   = (dx - (radius / 4)) + 'px';

                    uxRipple.style.backgroundColor = 'rgba(' + ((color) ? (color.match(/^(--)/g) ? 'var(' + color + ')' : color) : 'var(' + colorCss[Math.floor(Math.random() * (colorCss.length - 0)) + 0] + ')') + ', 1)';
                    uxRipple.style.pointerEvents   = 'none';
                    uxRipple.style.animation       = 'ux-ripple ' + window.ux.ripple.animation + 'ms ease-in-out';


                    uxRipple.addEventListener("animationend", removeRipple, false);
                    function removeRipple()
                    {
                        uxRipple.removeEventListener('animationend', removeRipple, false);
                        ripple.removeChild(uxRipple);
                        el.removeChild(ripple);

                        window.ux.ripple.launchOut = 0;
                    }

                    navigation(evt, window.ux.ripple.launchOut);
                }
                else
                {
                    navigation(evt, 0);
                }
            }, delay);
        }, 0);
    }


    /**
     * CONTROLLER OPEN/CLOSE NAV
     *
     * @param {events} evt
     * @returns {undefined}
     */
    function navigation(evt, timeout)
    {
        clearTimeoutNavigation();

        timer.nav = setTimeout((evt, timeout) =>
        {
            var uiHeader = document.querySelector('#ui-header');
            var el = null;
            var href = null;
            var uxDrawerNavSubToggle = null;
            var uiDrawerNavSubClass = 'ui-drawer-nav-sub-show';
            var uiDraweSearchClass = 'ui-drawer-search';
            var target = fn.target(evt);
            var uiBtnSearch = document.querySelector('#ui-btn-search i');
            var uxBtnSearch = function ()
            {
                if (fn.isset(uiBtnSearch))
                {
                    (uiBackdrop.classList.contains(uiDraweSearchClass))
                        ? uiBtnSearch.classList.add(uiDraweSearchClass)
                        : uiBtnSearch.classList.remove(uiDraweSearchClass);
                }

                textAreaElements(fn.uxTransitionDuration('#' + uiDraweSearchClass));
            };
            var uiBackdrop = document.querySelector('#ui-backdrop');
            var uxBackdrop = function ()
            {
                if (fn.isset(uiBackdrop))
                {
                    if (fn.isDesktop()) uiBackdrop.className = (uiBackdrop.classList.contains(uiDraweSearchClass)) ? uiDraweSearchClass : '';
                    else uiBackdrop.className = '';
                }

                uxBtnSearch();
            };


            while (fn.isset(target) && (target.tagName !== 'HTML') && !fn.isset(el))
            {
                if (target.dataset.drawer || (target === uiBackdrop)) el = target;
                if (fn.isset(target.href)) href = target;
                if (target.dataset.drawerNavSubToggle) uxDrawerNavSubToggle = target;

                target = target.parentNode;
            }

            if (el && fn.isset(uiHeader))
            {
                if (el === uiBackdrop) uxBackdrop();
                else
                {
                    uiBackdrop.classList.toggle(el.dataset.drawer);
                    uxBtnSearch();
                }
            }
            else if (href) uxBackdrop();
            else if (uxDrawerNavSubToggle && fn.isset(uiHeader))
            {
                var subNav = document.querySelector('*[data-drawer-nav-sub="' + uxDrawerNavSubToggle.dataset.drawerNavSubToggle + '"]');

                if (subNav)
                {
                    if (!subNav.classList.contains(uiDrawerNavSubClass))
                    {
                        subNav.style.zIndex = parseInt(timer.nav);
                        subNav.classList.add(uiDrawerNavSubClass);
                    }
                    else
                    {
                        subNav.classList.remove(uiDrawerNavSubClass);
                        subNav.removeAttribute('style');
                    }
                }

            }

        }, timeout, evt, timeout);
    }


    /******************************************************************
        UX OBSERVERS
     ******************************************************************/

    const uxElObserver = new IntersectionObserver((entries) =>
    {
        entries.forEach((entry, index) =>
        {
            let el = entry.target;

            if (entry.isIntersecting)
            {
                el.classList.add('ux-el-in-viewport');

                if (entry.intersectionRatio >= 0.016)
                {
                    el.classList.add('ux-el-is-visible');
                }
            }
            else
            {
                el.classList.remove('ux-el-in-viewport');
                el.removeAttribute("style");
            }
        });
    },
    {
        root       : null,
        rootMargin : '0px',
        threshold  : buildThresholdList()
    });


    function buildThresholdList()
    {
        let ratio      = 66;
        let thresholds = [];

        thresholds.push(0);

        for (let i = 1.0; i <= ratio; i++) thresholds.push(i / ratio);


        return thresholds;
    }

    const uxPageObserver = new MutationObserver((mutations, observer) =>
    {
        if(!document.querySelector('#ui-header'))
        {
            document.querySelector('#ui-backdrop').className = '';
        }

        mutations.forEach((mutation) =>
        {
            if (mutation.type === 'childList')
            {
                window.ux.loop(window.ux.ripple.launchOut);
            }
        });
    });


    window.ux.loop = function (timeout)
    {
        clearTimeout(timer.xloop);
        timer.xloop = setTimeout(() =>
        {
            uxElObserver.disconnect();

            document.querySelectorAll('#ui-page .ui-section:last-child').forEach((section) =>
            {
                section.querySelectorAll('.ux-el').forEach((el, index) =>
                {
                    el.style.transitionDelay = parseFloat((index + 1) * 160) + "ms";

                    uxElObserver.observe(el);
                });

                section.removeEventListener('scroll', scroll, optEventListener);
                section.addEventListener('scroll', scroll, optEventListener);

                function scroll(e) { isScrolled(e); }
            });
        }, ((timeout < 99) ? 99 : timeout));
    };


    /******************************************************************
        UX
    ******************************************************************/


    setTimeout(() =>
    {
        applyWhenElementExists('#ui-page .ui-section:last-child', window.ux.loop, 150);
    });

    function applyWhenElementExists(selector, myFunction, intervalTime)
    {
        var interval = setInterval(() =>
        {
            if (document.querySelectorAll(selector).length > 0)
            {
                clearInterval(interval);
                myFunction(0);

                uxPageObserver.observe(document.querySelector("#ui-page"),
                {
                    childList  : true,
                    attributes : false,
                    subtree    : false
                });
            }
        }, intervalTime);
    }


    /******************************************************************
        UX
    ******************************************************************/


    function textAreaElements(timeout)
    {
        clearTimeout(timer.textarea);

        var textareas = document.querySelectorAll('textarea');

        for (const textarea of textareas) textAreaAdjust(textarea, timeout);
    }


    function textAreaAdjust(obj, timeout)
    {
        timer.textarea = setTimeout(function (obj)
        {
            if (obj.tagName === 'TEXTAREA')
            {
                var val = obj.value;
                val = val.replace(/(^\s*)|(\s*$)/gi, '');
                val = val.replace(/[ ]{2,}/gi, ' ');
                val = val.replace(/[\n]{2,}/gi, '\n\n');
                val = val.replace(/[	]{2,}/gi, '');

                obj.value = val;
                obj.style.height = "auto";
                obj.style.height = obj.scrollHeight + "px";
            }
        }, timeout, obj);
    }

})();
